import { PageProps, graphql } from "gatsby";

import Container from "../components/container";
import React from "react";
import Title from "../components/title";

const WpPageTemplate = ({ data: { wpPage } }: PageProps<{ wpPage: any }>) => {
    const { title, content } = wpPage;
    return (
        <Container noHero>
            <Example title={title} content={content} />
        </Container>
    );
};
export const query = graphql`
    query ($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
        }
    }
`;
export default WpPageTemplate;

export function Example({ title, content }: { title: string; content: string }) {
    console.log("content",content);
    return (
        <div className="relative py-16 mx-auto overflow-hidden bg-white max-w-7xl">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg px-4 mx-auto sm:px-12">
                    <Title title={title} />
                    <div
                    id="content"
                    style={{ fontFamily: 'Roboto'}}
                        className="mt-8 text-center text-xl leading-8 text-black"
                        dangerouslySetInnerHTML={{
                            __html: content ? content.replace(/<svg/, '<svg style="display: none"') : "",
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
